import { Vue, Component, Prop } from 'vue-property-decorator';
import { ElForm } from 'element-ui/types/form';
import { crogService } from '../service/crogService';


@Component({
    name: "ManageCrog"
})
export default class ManageCrog extends Vue {
    @Prop()
    value?: ente.crog;

    get validationRules() {
        return {
            nome: [{ required: true, trigger: 'blur' }],
            descrizione: [{ required: true, trigger: 'blur' }],
            comune: [{ required: true, trigger: 'blur' }],
        }
    }

    manage: ente.crog = {
        id: null,
        nome: null,
        descrizione: null,
        indirizzo: null,
        cap: null,
        comune: null,
        provincia: null,
        nazione: null,
        telefono: null,
        email: null
    } as ente.crog;
    created() {
        if (this.value) {
            this.manage = Object.assign({}, this.value);
        }
    }

    get title() {
        if (!this.manage?.id)
            return `Aggiungi un nuovo ordine regionale`
        return `Modifica l'ordine regionale <strong>${this.value.nome}</strong>`
    }

    saving: boolean = false;
    async save() {
        (this.$refs.form as ElForm)?.validate(async valid => {
            if (valid) {
                try {
                    this.saving = true;
                    const result = await crogService.Set(this.manage);
                    this.saving = false;
                    this.$emit('change', result);
                } catch (e) {
                    console.log(e);
                    this.saving = false;
                }
            }
        })
    }
}